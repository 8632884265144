import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';

const Safety = ({ data }) => (
  <Layout>
    <SEO
      meta_title={data.prismicSafetyPage.data.meta_title}
      meta_description={data.prismicSafetyPage.data.meta_description}
    />
    <Hero
      title={data.prismicSafetyPage.data.hero_text.text}
      fluid={data.prismicSafetyPage.data.hero_image.localFile.childImageSharp.fluid}
      overlay="bg-black opacity-50"
    />

    <section className="bg-grey-darker text-grey-light">
      <div className="container">
        <div className="flex flex-wrap justify-center">
          <div className="w-full md:w-4/5 lg:w-1/2">
            <h2>{data.prismicSafetyPage.data.subheading.text}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicSafetyPage.data.content.html,
              }}
            />
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default Safety;

export const query = graphql`
  query safetyPage {
    prismicSafetyPage {
      id
      uid
      data {
        title {
          html
          text
        }
        subheading {
          html
          text
        }
        content {
          html
          text
        }
        hero_image {
          alt
          url
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 1600, quality: 80, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        hero_text {
          html
          text
        }
        meta_title
        meta_description
      }
    }
  }
`;
